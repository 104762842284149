import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";

// elements
import Checkbox from "@/shared/Checkbox";

// helpers
import useClickOutside from "@/hooks/useClickOutside";

// interfaces
import { WhereSelectItemProps } from "./WhereSelectItem.interface";

// assets
import styles from "@/assets/styles/components/where-select.module.scss";

const WhereSelectItem = (props: WhereSelectItemProps) => {
  const {
    data,
    isBranch = false,
    handleHeightCb,
    parentOpen,
    handleSelect,
    selected,
    isStartNode = false,
    goBack,
    setNodes,
    isDisabled = false,
    isMobile
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const multiRef = useRef(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isChecked = (selected || []).some(
    (el) => el.value.toString() === data.value.toString() && el.type === data.type
  );
  const shouldRender = isMobile ? true : isStartNode || parentOpen;

  const isLeaf = !data.children;

  useEffect(() => {
    if (isOpen) {
      setNodes(data);
    } else {
      goBack(data);
    }

    if ((isOpen || parentOpen) && contentRef.current) {
      handleHeightCb(contentRef.current.getBoundingClientRect().height + 10);
    }
  }, [isOpen]);

  useClickOutside(multiRef, () => setIsOpen(false));

  function toggleChecked() {
    if (!isChecked) {
      handleSelect({ ...data, checked: true });
    } else {
      handleSelect({ ...data, checked: false });
    }
  }

  const renderChildren = () => {
    const children = [...(data?.children || [])];
    const greaterLondonIndex = children.findIndex((el) => el.label === "Greater London");

    if (greaterLondonIndex !== -1) {
      const greaterLondon = children[greaterLondonIndex];
      children.splice(greaterLondonIndex, 1);
      children.unshift(greaterLondon);
    }

    return children.map((childData, index) => (
      <WhereSelectItem
        key={`${childData.label}-${index}`}
        data={childData}
        isBranch={true}
        handleHeightCb={handleHeightCb}
        parentOpen={isOpen}
        handleSelect={handleSelect}
        selected={selected}
        goBack={goBack}
        setNodes={setNodes}
        isMobile={isMobile}
      />
    ));
  };

  const whereSelectItemClassName = classNames(styles["locl-where-select-item"], {
    [styles["locl-where-select-item_branch"]]: isBranch,
    [styles["locl-where-select-item_disabled"]]: isDisabled,
    [styles["locl-where-select-item_mobile"]]: isMobile && !isStartNode
  });

  const handleExpandItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    !isDisabled && setIsOpen(true);
  };

  const itemLabelClassName = classNames(styles["locl-where-select-item__inner"], {
    [styles["locl-where-select-item__inner_opened"]]: isOpen,
    [styles["locl-where-select-item__inner_mobile"]]: isMobile && parentOpen
  });

  return (
    <div ref={multiRef} className={whereSelectItemClassName} data-testid="locl-where-select-item">
      <div
        className={`${styles["locl-where-select-item__content"]} ${
          shouldRender ? styles["locl-where-select-item__content_open"] : ""
        }`}
      >
        <Checkbox
          size="small"
          className={itemLabelClassName}
          checked={isChecked}
          onChange={toggleChecked}
          data-testid="locl-where-select-item-inner"
        >
          <div className={styles["locl-where-select-item__inner-content"]}>
            {data.label}
            {!isLeaf ? (
              <span
                onClick={handleExpandItem}
                className={styles["locl-where-select-item__inner-arrow"]}
                data-testid="locl-where-select-item-inner-arrow"
              >
                <FaChevronDown />
              </span>
            ) : null}
          </div>
        </Checkbox>
        <div
          className={`${styles["locl-where-select-item__children"]} ${
            isOpen ? styles["locl-where-select-item__children_open"] : ""
          }`}
          data-testid="locl-where-select-item-children"
          ref={contentRef}
        >
          {!isMobile && renderChildren()}
        </div>
      </div>
    </div>
  );
};

export default WhereSelectItem;
