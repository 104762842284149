"use client";

import React, { useState, useRef, useEffect } from "react";
import dynamic from "next/dynamic";
import { TimeRangePickerProps, Popover, ConfigProvider } from "antd";
import type DatePickerType from "antd/lib/date-picker";
import dayjs from "dayjs";
import locale from "antd/lib/locale/en_GB";
import "dayjs/locale/en-gb";
const RangePicker = dynamic(() => import("antd").then(mod => mod.DatePicker.RangePicker), {
  ssr: false
}) as typeof DatePickerType.RangePicker;

// components
import Button from "@/shared/Button";
import WhenDropdownPickerInput from "./WhenDropdownPickerInput";
import { WhenDropdownPickerProps } from "./WhenDropdownPicker.interface";

// helpers
import { useDimension } from "@/hooks/useDimensions";
import useClickOutside from "@/hooks/useClickOutside";
import calendar from "@/api/query/calendar";

// assets
import styles from "@/assets/styles/components/when-dropdown-picker.module.scss";


function WhenDropdownPicker(props: WhenDropdownPickerProps) {
  const {
    children,
    value: controlledValue,
    onChange,
    onPresetClick,
    defaultValue,
    className,
    overlayClassName
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>(defaultValue || [null, null]);
  const [customPeriodId, setCustomPeriodId] = useState<number | null>(null);

  const [width] = useDimension();

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsOpen(false));

  const { data } = calendar.useWhenSelectorDates();


  const presetOptions: TimeRangePickerProps["presets"] = data?.results
    .sort((current, next) => current.position - next.position)
    .map(item => ({
      label: <span onClick={() => onPresetClick && onPresetClick(item.id)}>{ item.name }</span>,
      value: [dayjs(item.dateRange.start), dayjs(item.dateRange.end)],
    }));

  // methods
  const handleSetDate = () => {
    onChange([...value, customPeriodId]);
    setIsOpen(false);
  };

  const handleClearDate = () => {
    onChange([null, null, null]);
    setValue([null, null]);

    onPresetClick && onPresetClick(null);
  };

  const handleCalendarChange = (
    datesDayjsFormat: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    datesStringFormat: [string | null, string | null]
  ) => {
    const periodId = data?.results.find(
      item => item.dateRange.start === datesStringFormat[0] && item.dateRange.end === datesStringFormat[1]
    )?.id || null;

    setCustomPeriodId(periodId);
    setValue(datesDayjsFormat || [null, null])
  }

  const renderControls = () => (
    <div className={styles["locl-when-dropdown-picker__popup__footer"]}>
      <Button fullWidth={width <= 768} ghost success onClick={handleClearDate} data-testid="locl-when-dropdown-button-clear">Clear</Button>
      <Button fullWidth={width <= 768} danger onClick={handleSetDate} data-testid="locl-when-dropdown-button-set-date">Set Date</Button>
    </div>
  )

  // effects
  useEffect(() => {
    if (controlledValue) {
      setValue(controlledValue);
    }
  }, [JSON.stringify(controlledValue)]);

  // renders
  return (
    <ConfigProvider locale={locale}>
      <Popover
        overlayClassName={`${styles["locl-when-dropdown-picker-overlay"]} ${overlayClassName}`}
        className={`locl-when-dropdown-picker ${className}`}
        rootClassName={styles["locl-when-dropdown-picker__root"]}
        placement={process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" ? "bottomLeft" : "leftTop"}
        autoAdjustOverflow={false}
        onOpenChange={setIsOpen}
        trigger="click"
        content={() => (
          <div ref={ref}>
            <RangePicker
              getPopupContainer={() => ref.current as HTMLDivElement}
              open={isOpen}
              presets={presetOptions}
              value={value}
              placement="bottomLeft"
              className="locl-when-dropdown-picker"
              popupClassName={styles["locl-when-dropdown-picker__popup"]}
              onChange={handleCalendarChange}
              disabledDate={date => date.isBefore(dayjs(), "day")}
              panelRender={panelNode => (
                <div className="locl-when-dropdown-picker__popup__panel">
                  { width <= 768 && renderControls() }
                  <WhenDropdownPickerInput
                    onChange={setValue}
                    value={value}
                  />
                  { panelNode }
                </div>
              )}
              renderExtraFooter={() => width > 768 && renderControls()}
            />
          </div>
        )}
      >
        { children }
      </Popover>
    </ConfigProvider>
  );
}

export default WhenDropdownPicker;
