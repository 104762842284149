import DropdownComponent from "./Dropdown";
import DropdownButton from "./DropdownButton";
import { DropdownProps, DropdownMenuItemProps } from "./Dropdown.interface";

const Dropdown = DropdownComponent as React.FC<DropdownProps> & {
  Button: typeof DropdownButton;
};

Dropdown.Button = DropdownButton;

export type { DropdownProps, DropdownMenuItemProps };
export default Dropdown;
