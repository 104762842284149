import { IUseQueryResult } from "@/types/api/IUseQueryResult.interface";

// helpers
import { useFetch } from "./reactQuery";
import API_ROUTES from "@/api/routes.constant";

// interfaces
import { QueryArgsInterface } from "@/types/api/queryArgs.interface";
import { WhenSelectorDatesInterface } from "@/types/api/WhenSelectorDates.interface";
import { IPaginatedResponse } from "@/types/api/PaginatedResponse";

const { calendarUrls } = API_ROUTES;

const calendar = {
  useWhenSelectorDates: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch(calendarUrls.whenSelectorDates, params, config) as
    IUseQueryResult<IPaginatedResponse<WhenSelectorDatesInterface>>;
  },
};

export default calendar;
