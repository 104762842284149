/* eslint-disable @typescript-eslint/no-explicit-any */
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

// interfaces
import { ICountryRespInterface, RegionRespInterface } from "@/types/api/CountryStateCity.interface";

// TODO refactor fucking file to remove all any
export interface TreeNodeChild {
  label: string;
  type: "city";
  value: number | number[];
}

export interface TreeNode {
  label: string;
  type: "region" | "city";
  value: number | number[];
  expanded: boolean;
  children?: TreeNodeChild[];
}


export function formatRegion(arr: RegionRespInterface[]): TreeNode[] {
  return arr.map((element) => ({
    label: element.name,
    type: "region",
    value: element.cities.map(city => city.id),
    expanded: true,
    children: element.cities.map(city => ({
      label: city.name,
      value: city.id,
      type: "city"
    }))
  }));
}

export function formatRegions(data: ICountryRespInterface[]) {
  return data.map((country) => {
    const children = formatRegion(country.regions);
    const value = children
      .reduce((newArray, item) => [...newArray, ...item.value as any] as any, []) as number[];

    return {
      label: country.name,
      value: value,
      type: "country",
      expanded: true,
      children: formatRegion(country.regions)
    };
  });
}

export const areEqual = (obj1: any, obj2: any) => {
  const propertyToOmit = ["checked", "expanded"];

  return isEqual(omit(obj1, propertyToOmit), omit(obj2, propertyToOmit));
};

export const flattenChildrenRecursive = (node: any) => {
  const children = node.children;

  if (children) {
    return children.reduce(
      (acc: any, child: any) => [...acc, ...flattenChildrenRecursive(child)],
      [{ ...node, children, childrenIds: children.map((el: any) => el.value) }]
    );
  }

  return [node];
};

export const flattenLocationArray = (locationArray: any) => {
  return locationArray.reduce((acc: any, location: any) => {
    return [...acc, ...flattenChildrenRecursive(location)];
  }, []);
};

export const removeChildrenFromFlattenLocations = (locations: any, locationValue = null) => {
  let newLocations = [...(locations || [])];

  newLocations.forEach((location) => {
    if (location.childrenIds?.length && (location.value === locationValue || !locationValue)) {
      newLocations = newLocations.filter((l) => !location.childrenIds.includes(l.value));
    }
  });

  return newLocations;
};
export const unselectLocationsByChildren = (selectedLocations: any, type: any, childType: any) => {
  const selectedByType = selectedLocations.filter((el: any) => el.type === type);
  const regionsToRemove = selectedByType.filter(
    (el: any) => el.childrenIds.filter((cId: any) => selectedLocations
      .filter((c: any) => c.type === childType)
      .map((sl: any) => sl.value)
      .includes(cId)
    ).length !== el.childrenIds.length
  );

  return selectedLocations.filter(
    (el: any) => !regionsToRemove.map((r: any) => r.value).includes(el.value)
  );
};

export const selectLocationsByChildren = (
  sourceData: any,
  selectedLocations: any,
  type: any,
  childType: any
) => {
  const dataByType = flattenLocationArray(sourceData).filter((el: any) => el.type === type);
  const locationsToAdd = dataByType.filter(
    (l: any) => l.childrenIds.filter((cId: any) => selectedLocations
      .filter((c: any) => c.type === childType)
      .map((sl: any) => sl.value)
      .includes(cId)
    ).length === l.childrenIds.length
  );

  return [...selectedLocations, ...locationsToAdd];
};

export const getSelectedValues = (countriesList: any, values: any) => {
  const cityOptionsList = formatRegions(countriesList || []).filter((item: any) => item.label);
  const cityOptionsListAll = flattenLocationArray(cityOptionsList || []).filter(
    (item: any) => item.label
  );

  const transformedValues = Array.isArray(values) ? values.map(Number) : [+values].filter(Boolean);

  const selectedCityOptions = cityOptionsListAll.filter((item: any) => item.value.some
    ? item.value.every((id: any) => transformedValues.includes(id))
    : transformedValues.includes(item.value)
  );

  return removeChildrenFromFlattenLocations(selectedCityOptions);
};
