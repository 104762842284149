// helpers
import { useFetch } from "./reactQuery";
import { pathToUrl } from "./reactQuery/pathToUrl";
import API_URLS from "@/api/routes.constant";

// interfaces
import { AbstractRespListItemInterface } from "@/types/api/AbstractRespListItem.interface";
import { ICountryRespInterface } from "@/types/api/CountryStateCity.interface";
import { UseQueryInterface } from "@/types/api/queryArgs.interface";

const { countryStateCityUrls } = API_URLS;

const countryStateCity = {
  useCountriesList: () => useFetch<AbstractRespListItemInterface[]>(countryStateCityUrls.countries),
  useRegionsList: (countryId?: number) => {
    return useFetch<AbstractRespListItemInterface[]>(
      countryId ? pathToUrl(countryStateCityUrls.regions, { countryId }) : null
    );
  },
  useCitiesList: ({ countryId, regionId }: { countryId?: number, regionId?: number }) => {
    const fetchKey = (countryId && regionId)
      ? pathToUrl(countryStateCityUrls.cities, { countryId, regionId })
      : "";

    return useFetch<AbstractRespListItemInterface[]>(fetchKey);
  },
  useCountriesFilters: (params: Record<string, unknown>, config?: UseQueryInterface) => {

    return useFetch<ICountryRespInterface[]>(countryStateCityUrls.countriesFilters, params, config);
  },
};

export default countryStateCity;
